<template>
  <span class="" v-if="blocked">
    <slot></slot>
    <v-tooltip bottom v-if="icon" open-delay="600" class="">
      <template right v-slot:activator="{ on, attrs }">
        <i v-cHex="color" class="ms-1 me-1 relative" v-i:ic#lock-open#12 v-bind="attrs" v-on="on" style="top: -2px;"></i>
      </template>
      <p class="m-0 mt-n1 p-0 fs-7pt f-sanspro fw-600 line-height-0 text-left">
        <i v-i:ic#lock-open#12></i>
        <span v-if="equipeID == 'gestores'">
          Funcionalidade liberada apenas p/ gestão de área
        </span>
      </p>
    </v-tooltip>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "spanblocked",
  components: {},
  props: {
    area: { default: null, type: Object },
    icon: { default: false, type: Boolean },
    color: { default: "#000000", type: String },
    itemName: { default: "item name", type: String },
    equipeID: { default: "equipeID", type: String },
  },
  computed: {
    blocked() {
      if(this.area != null && this.equipeID == "gestores") {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        return (this.isUserAdmin || gestores.indexOf(this.$store.state.user.cargo) != -1 && this.$store.state.user.areasIC[this.area.id] != undefined);
      }
      return false;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      equipe: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
