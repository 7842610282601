<template>
  <div class="">
    <p class="m-0 p-0">
      Detalhes da UC:
    </p>
    <v-card>
      <ucitem2 :uc="uc" :area="area"/>
    </v-card>
    <p class="m-0 p-0 mt-3">
      Metas Indicadas:
      <span class="ms-2 rounded px-1 f-roboto fw-500 fs-11pt" v-if="metas != null" v-c:B :style="'background-color:'+area.cor">
        {{Object.keys(metas).length}}
      </span>
    </p>
    <v-card class="p-2">
      <v-alert type="info" class="mt-2" v-if="metas == null" v-c:K :color="hexTints(area.cor,0.9)">
        <p class="m-0 p-0 f-sanspro fs-11pt">
          Aguardando a indicação de metas de compreensão
        </p>
      </v-alert>
      <v-card class="p-2 my-1" v-for="(meta,key,index) in metas" :key="key">
        <div class="m-0 p-0" v-if="isGestores || isUserAdmin">
          <div class="right mx-0 px-0 mb-5">
            <qtdequestoespormeta :area="area" :meta="meta" />
          </div>
          <div class="left mx-2 px-0" v-if="meta.metaCodigo != ''">
            <span class="rounded px-1" v-bg:S#5>
              <span class="ms-0 f-sanspro fs-8pt fw-600">ID:</span>
              <span class="ms-2 f-roboto fs-8pt fw-700">{{meta.metaCodigo}}</span>
            </span>
          </div>
        </div>
        <div class="clear mx-2">
          <readmore class="text-dark mt-0 fs-10pt f-raleway fw-400" size="200" :text="meta.texto" />
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ucitem2 from "./uc-item2.vue";
import moment from "moment"
import qtdequestoespormeta from "@/views/cursos/planos/qtdeQuestoesPorMeta.vue"
import readmore from "@/components/readmore.vue"

export default {
  name: "carduc",
  components: { readmore, ucitem2, qtdequestoespormeta },
  props: {
    uc: { default: null, type: Object },
    area: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      metas: null,
    }
  },
  watch: {
    uc() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    //self.loading = true;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      console.log("self.uc.id",self.uc.id);
      rdb.ref('/curriculo/avaliacao/2021S2/metasIndicadasFinal').orderByChild("ucID").equalTo(self.uc.id).on('value',function(snapshot) {
        self.metas = snapshot.val();
        console.log("self.metas",self.metas);
      });
    }
  }
}
</script>

<style scoped>
</style>
