<template>
  <v-card v-if="area != null" :style="'background: linear-gradient(#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,'+area.cor+'95);'">
    <v-card-text class="text-left text-justify mb-0 pb-2">
      <v-row no-gutters>
        <v-col cols="9" sm="10">
          <div class="mb-2 text-truncate">
            <tagarea class="me-1" :sigla="area.sigla"/>
            <span class="f-poppins fw-700 fs-8pt">{{area.nome}}</span>
          </div>
          <v-row no-gutters justify="start" class="mt-n1">
            <v-col cols="auto" style="margin-top: -2px;">
              <i v-i:ic#circle#14 class="me-1"></i>
            </v-col>
            <v-col cols="auto" class="me-1">
              <v-card flat class="text-truncate transparent" width="150px">
                <span class="f-poppins fw-500 fs-8pt">UCs Ofertadas 2021/2</span>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <v-card flat v-cHex="'#808080'" class="f-roboto fw-800 fs-11pt transparent" style="width: 36px; padding-top: 2px;">
                {{qtdeUcsOfertadas}}
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters justify="start" class="mt-n1">
            <v-col cols="auto" style="margin-top: -2px;">
              <i v-i:ic#circle#14 class="me-1" v-cHex="'#006a1f'"></i>
            </v-col>
            <v-col cols="auto" class="me-1">
              <v-card flat class="text-truncate transparent" width="150px">
                <span class="f-poppins fw-500 fs-8pt">UCs com Metas Aprovadas</span>
              </v-card>
            </v-col>
            <v-col cols="auto" class="">
              <v-card flat v-cHex="'#006a1f'" class="f-roboto fw-800 fs-11pt text-center transparent" style="padding-top: 2px;">
                {{qtdeUcsAprovadas}}
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters justify="start" class="mt-n1">
            <v-col cols="auto" style="margin-top: -2px;">
              <i v-i:ic#circle#14 class="me-1" v-cHex="'#c2001d'"></i>
            </v-col>
            <v-col cols="auto" class="me-1">
              <v-card flat class="text-truncate transparent" width="150px">
                <span class="f-poppins fw-500 fs-8pt">UCs - Metas em correção</span>
              </v-card>
            </v-col>
            <v-col cols="auto" class="">
              <v-card flat v-cHex="'#c2001d'" class="f-roboto fw-800 fs-11pt text-center transparent" style="padding-top: 2px;">
                {{qtdeUcsCorrecao}}
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" sm="2" class="ms-n2">
          <v-progress-circular :rotate="360" :size="65" :width="10" :value="parseInt((qtdeUcsAprovadas/qtdeUcsOfertadas)*100)" :color="'#006a1f'">
            {{ parseInt((qtdeUcsAprovadas/qtdeUcsOfertadas)*100)  }}%
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-card-text>
    <div class="m-0 p-0 text-right mt-n4 pb-2" v-if="verDetalhes">
      <v-btn class="text-nonecase f-poppins" text x-small @click="$emit('clickDetalhes',area)">ver detalhes</v-btn>
    </div>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/views/cursos/tagArea.vue";

export default {
  name: "cardareaa2",
  components: { tagarea },
  props: {
    area: { default: null, type: Object },
    verDetalhes: { default: true, type: Boolean },
  },
  computed: {
    fundo() {
      return 'background: linear-gradient(0deg, '+this.area.cor+' 50%, '+this.area.cor+' 100%);'
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      qtdeUcsOfertadas: 0,
      qtdeUcsAprovadas: 0,
      qtdeUcsCorrecao: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;

    rdb.ref('/curriculo/ucs/').orderByChild("oferta2021S2").equalTo(true).on('value',function(snapshot) {
      var ucs = snapshot.val();
      var ucsArea = {};
      //console.log("ucs",ucs);
      self.qtdeUcsOfertadas = 0;
      for(var key in ucs) {
        if(ucs[key].areas[self.area.id] != undefined) {
          self.qtdeUcsOfertadas++;
          ucsArea[key] = ucs[key];
        }
      }
      //console.log("self.qtdeUcsOfertadas",self.qtdeUcsOfertadas);
      rdb.ref('/curriculo/avaliacao/2021S2/ucsA2Indicadas/').on('value',function(snapshot) {
        var ucs = snapshot.val();
        self.qtdeUcsAprovadas = 0;
        self.qtdeUcsCorrecao = 0;
        for(var key in ucs) {
          if(ucsArea[key] != undefined) {
            if(ucs[key].correcao) {
              self.qtdeUcsCorrecao++;
            } else {
              self.qtdeUcsAprovadas++;
            }
          }
        }
      });
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>

.fundo {
  background: rgb(2,0,36);
  background: linear-gradient(180deg, #ff0000 0%, rgba(26,26,116,1) 70%, rgba(12,110,130,1) 100%);
}

</style>
