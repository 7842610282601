<template>
  <span>
    <v-btn @click="$refs.dialogmetadetalhesref.show()" class="mx-1 px-1 text-nonecase f-sanspro fw-300 fs-10pt" :color="area.cor" text x-small>
      <i v-i:duo#eye#14 class="btn-icon-left me-0"></i>
      <span class="ms-0 me-1 f-sanspro fw-600 ms-1 fs-8pt">ver detalhes</span>
    </v-btn>
    <span v-if="questoes.length > 0">
      <span class="f-sanspro fw-800 fs-9pt" v-cHex="hexTints(area.cor,0.4)">{{questoes.length}}</span>
      <span class="ms-1 me-0 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="questoes.length == 1">questão</span>
      <span class="ms-1 me-2 f-sanspro fw-600 ms-1 fs-8pt" v-cHex="hexTints(area.cor,0.4)" v-if="questoes.length>1">questões</span>
      <spanblocked equipeID="gestores" :color="area.cor" icon />
      <span class="me-1 rounded py-0 ps-1 pe-1 fs-8pt fw-600 f-sanspro" v-c:B :style="'background-color:'+area.cor">
        <span v-i:duo#star#12 v-c:B class="relative" style="right: 0px; top: -1px;"></span>
        A2
      </span>
    </span>
    <dialogmetadetalhes ref="dialogmetadetalhesref" :meta="meta" :area="area" />
  </span>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import spanblocked from "./spanBlocked.vue"
import dialogmetadetalhes from "@/views/cursos/avaliacao/metaDetalhes/dialogMetaDetalhes.vue"

export default {
  name: "qtdequestoespormeta",
  components: { spanblocked, dialogmetadetalhes },
  props: [ "meta", "area" ],
  computed: {},
  data(){
    return {
      questoes: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    this.buildQuestoes();
  },
  methods:{

    buildQuestoes() {
      var self = this;
      rdb.ref("/curriculo/questoesValidas/").orderByChild("metaID").equalTo(this.meta.id).on('value',function(snapshot) {
        self.questoes = snapshotValToArray(snapshot.val());
        //console.log("questoes");
        //console.log(self.questoes);
      });
    },

    qtdeClick() {
      var self = this;
      console.log("qtdeClick");
      this.eventBus.$emit("toggleBoxQuestoes",{
        slotKey: "second",
        title: "Lista de itens",
        dados: {
          meta: self.meta,
          questoes: self.questoes
        }
      });
    }


  }
}
</script>

<style scoped>

</style>
