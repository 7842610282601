<template>
  <v-row class="">
    <v-col cols="12">
      <v-row class="pt-1">
        <v-col cols="12" sm="5" md="4" v-for="(area,key,index) in areas" :key="key">
          <cardareaa2 :area="area" @clickDetalhes="$refs.acompanhaarearef.show(area)"/>
        </v-col>
      </v-row>
      <v-row class="pt-1">
        <v-col cols="12">
        </v-col>
      </v-row>
    </v-col>

    <acompanhaarea ref="acompanhaarearef" />

  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import cardareaa2 from "./card-areaA2.vue"
import acompanhaarea from "./acompanha-area.vue"

export default {
  name: "acompanhamento",
  components: { cardareaa2, acompanhaarea },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      areas: {},
      areaSel: {},
      showAcompanhaArea: false,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/areas/').on('value',function(snapshot) {
      self.areas = snapshot.val();
      console.log("self.areas",self.areas);
      //self.build();
    });
  },
  methods:{

    build() {
      var self = this;
    },

    clickDetalhes(area) {
      console.log("clickDetalhes",area);
      this.showAcompanhaArea = true;
    }

  }
}
</script>

<style scoped>
</style>
