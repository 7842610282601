<template>
  <v-list-item class="m-0 p-0"  style="height: 60px; min-height: 60px; max-height: 60px;" v-if="area != null && uc != null" @click="$emit('selecionado',uc)">
    <v-list-item-content class="mx-1">
      <div class="p-2 my-0" style="width: 100%">
        <v-row no-gutters justify="start">
          <v-col cols="9" class="">
            <v-card width="32" flat class="left text-center mt-n2 me-3 transparent">
              <i v-i:duo#graduation-cap#32 class=""></i>
            </v-card>
            <div class="m-0 p-0 mt-0 text-truncate ms-2 pe-3">
              <span class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-12pt f-raleway">
                {{uc.nome}}
              </span>
            </div>
            <div class="m-0 mt-0 p-0" style="" v-if="uc != null">
              <span v-for="(area, key, index) in uc.areas" :key="key">
                <tagarea class="me-1" :sigla="area.sigla"/>
              </span>
            </div>
          </v-col>
          <v-col cols="2">
            <v-card v-if="uc != undefined && uc.correcao == undefined" width="66" flat :color="hexTints(area.cor,0.9)" class="text-center line-height-0 pt-1 pb-2 mt-1 me-1">
              <span color="" class="f-roboto fs-7pt text-nonecase" x-small>
                aguardando
              </span>
            </v-card>
            <v-card v-if="uc != undefined && uc.correcao == false" width="66" flat :color="hexTints(cor.active.V,0.5)" class="text-center line-height-0 pt-1 pb-2 mt-1 me-1">
              <span color="" class="f-roboto fs-7pt text-nonecase" x-small>
                aprovada
              </span>
            </v-card>
            <v-card v-if="uc != undefined && uc.correcao == true" width="66" flat :color="hexShades(cor.active.D,0.3)" class="text-center line-height-0 pt-1 pb-2 mt-1 me-1">
              <span color="" class="f-roboto fs-7pt text-nonecase" x-small v-c:B>
                em correção
              </span>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/views/cursos/tagArea.vue";

export default {
  name: "ucitem",
  components: { tagarea },
  props: {
    uc: { default: null, type: Object },
    area: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
