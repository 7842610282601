<template>
  <v-toolbar class="pt-2" :color="cor.active.b">
    <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
      Meta de compreensão
      <span class="ms-1 fs-10pt">(Detalhes)</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-title class="f-sanspro fw-600 fs-12pt" v-if="uc != null">
      <div class="">
        <div class="m-0 p-0 mt-0">
          <i v-i:duo#graduation-cap#20 class="btn-icon-left me-1"></i>
          <span class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-11pt f-raleway">
            {{uc.nome}}
          </span>
        </div>
        <div class="m-0 mt-n1 p-0" style="" v-if="uc != null">
          <span v-for="(area, key, index) in uc.areas" :key="key">
            <tagarea class="me-1" :sigla="area.sigla"/>
          </span>
        </div>
      </div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
  </v-toolbar>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "dialogtoolbar",
    components: {},
    props: {
      uc: { default: null, type: "Object" }
    },
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //self.loading = true;
      //rdb.ref('').on('value',function(snapshot) {
      //  self.algo = snapshot.val();
      //  console.log("self.algo",self.algo);
      //  self.build();
      //});
    },
    methods:{

      build() {
          var self = this;
      }
    }
  }
</script>

<style scoped>
</style>
